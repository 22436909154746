import { FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
export const Navigation = (props) => {
  const navStyle = {
    backgroundColor: '#FFFFFF',
  };
  const barraNavText={
    color: '#145388',
  };

  const iconContainer = {


    color: '#25d366',
    fontSize: '30',

    
  };
  const icon2Container = {


    color: '#145388',
    fontSize: '30',

    
  };
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top' style={navStyle}>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>

          <a  className='navbar-brand page-scroll barraNavText' href='#page-top' style={barraNavText}>
            Remain Computers
          </a>{' '}
          
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right barraNavText'>
            <li>
              <a href='#features' className='page-scroll' style={barraNavText}>
                Destrezas
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll' style={barraNavText}>
                Nosotros
              </a>
            </li>

            <li>
              <a href='#services' className='page-scroll' style={barraNavText}>
                Servicios
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll' style={barraNavText}>
                Galeria
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll' style={barraNavText}>
                Contactenos
              </a>
            </li>
            <li >
                <a href='https://wa.me/51985405090' >
                  <FaWhatsapp style={iconContainer} />
                </a>
            </li>
            <li >
                <a href='mailto:info@remaincomputers.com' >
                  <MdEmail style={icon2Container} />
                </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
