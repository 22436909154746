import { useState } from 'react';
import emailjs from 'emailjs-com';

const initialState = {
  name: '',
  email: '',
  message: '',
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const inputStyle = {
    padding: '20px',
  };

  const areaStyle = {
    padding: '20px',
  };

  const colorContactenos = {
    color: '#145388',
  };

  const clearState = () => {
    setState({ ...initialState });
    setShowMessage(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('servicio_infoRC', 'template_coom9om', e.target, 'hsPZtoYmn6sSXY2XY')
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    
    <div style={colorContactenos}>

      <div id='contact' >
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contactanos</h2>
                <p>
                 Porfavor ingresar los siguientes datos :
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group '>
                      <input
                        type='text'
                        id='name'
                        name='user_name'
                        className='form-control '
                        placeholder='Nombres'
                        required
                        onChange={handleChange}
                        style={inputStyle}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='user_email'
                        className='form-control'
                        placeholder='Correo'
                        required
                        onChange={handleChange}
                        style={inputStyle}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensaje'
                    required
                    onChange={handleChange}
                    style={areaStyle}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Enviar Mensaje
                </button>
              </form>
              {showMessage && (
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>Mensaje Enviado</h5>
              <p className='card-text'>¡Gracias por contactarnos! Tu mensaje ha sido enviado con éxito.</p>
            </div>
          </div>
        )}
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Informacion de contacto</h3>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Celular
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Correo
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.mail : '/'}>
                      <i className='fa fa-envelope'></i>
                    </a>
                  </li>

                  <li>
                    <a href={props.data ? props.data.whatsapp : '/'}>
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
