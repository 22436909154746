import ParticlesBg from "particles-bg";

export const Header = (props) => {
  const configuracionParticulas = {
    num: 10, // Ajusta el número de partículas
    size: 3, // Ajusta el tamaño de las partículas
    zIndex: 0,
    position: "absolute",
    top: 1,
  };
  const headerStyle = {
     // Ajusta la altura del header según sea necesario
  };
  const fondoBoton={
    backgroundColor: 'white',
    color: '#145388',
  };
  const introTextStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    paddingTop: "20%",
    fontSize: "10px",
    textAlign: "center"
  };

  return (
    <header id='header' style={headerStyle}  >
      <div className='intro' >
        <ParticlesBg type="circle" bg={configuracionParticulas} color="#145388" />
        <div className='overlay'>
          <div className='container'>
          
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text' style={introTextStyle} >
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a style={fondoBoton} 
                  href='#features'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Descubre mas 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
